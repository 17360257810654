/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import "./spec-label-mapper.css";
import _ from "lodash";

const SpecModelMapper = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [specMappings, setSpecMappings] = useState<any>([]);
  const [specLabelMappings, setSpecLabelMappings] = useState<any>([]);
  const [updatedSpecLabelMappings, setUpdatedSpecLabelMappings] = useState<any>(
    []
  );
  const [invalidItemIdIndexes, setInvalidItemIdIndexes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSpecMappings, setUpdatedSpecMappings] = useState<any>([]);
  const [specModels, setSpecModels] = useState<any>([]);
  const [modelItemId, setModelItemId] = useState<any>("");
  const [onSave, setOnSave] = useState(false);

  useEffect(() => {
    setOnSave(false);
    setInvalidItemIdIndexes([]);
    getSpecConfigurations();
    getSpecModels();
  }, []);
  const getSpecModels = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("get_all_spec_models");
      setSpecModels(_.cloneDeep(response.data));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("get_all_spec_sections");
      let allSections = _.cloneDeep(response.data);
      // allSections = _.map(allSections, (sec) => {
      //   let prop = _.map(sec.labels, (p) => {
      //     return {
      //       id: p.id,
      //       name: p.name,
      //       type: p.type,
      //       values: p.values,
      //       collapse: true,
      //     };
      //   });
      //   sec.labels = prop;
      //   return sec;
      // });
      setSections(_.cloneDeep(allSections));

      const mappingsResponse = await axiosPrivate.get(
        "get_all_spec_label_mappings"
      );

      const allMappings = _.map(allSections, (section) => {
        const mapping = _.find(mappingsResponse.data, {
          sectionId: section.id,
        });
        if (_.isEmpty(mapping)) {
          return {
            sectionId: section.id,
            mappings: [],
          };
        }
        return mapping;
      });
      setSpecLabelMappings(_.cloneDeep(allMappings));
      // setUpdatedSpecLabelMappings(_.cloneDeep(allMappings));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const getModelBomByModelItemId = async (item_id: any) => {
    try {
      setIsLoading(true);
      const modelBom = await axiosPrivate.get(
        `get_model_bom_by_model_item_id/${item_id}`
      );

      const itemIds = _.map(modelBom.data, (bom) => bom.itemId);
      const labelMappings = _.map(
        _.cloneDeep(specLabelMappings),
        (labelMap) => {
          const mappings = _.filter(labelMap.mappings, (map) =>
            _.includes(itemIds, map.itemId)
          );
          labelMap.mappings = mappings;
          return labelMap;
        }
      );
      setUpdatedSpecLabelMappings(labelMappings);
      // const allMappings = _.map(sections, (section) => {
      //   const mapping = _.find(mappingsResponse.data?.sections, {
      //     sectionId: section.id,
      //   });
      //   if (_.isEmpty(mapping)) {
      //     return {
      //       sectionId: section.id,
      //       mappings: [],
      //     };
      //   }
      //   return mapping;
      // });
      // setSpecMappings(_.cloneDeep(allMappings));
      // setUpdatedSpecMappings(_.cloneDeep(allMappings));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const getSpecModelMappingsByModelItemIdAndMap = async (item_id: any) => {
    try {
      setIsLoading(true);

      const mappingsResponse = await axiosPrivate.get(
        `get_spec_model_mappings_by_model_item_id/${item_id}`
      );

      const allMappings = _.map(sections, (section) => {
        const mapping = _.find(mappingsResponse.data?.sections, {
          sectionId: section.id,
        });
        if (_.isEmpty(mapping)) {
          return {
            sectionId: section.id,
            mappings: [],
          };
        }
        return mapping;
      });
      setSpecMappings(_.cloneDeep(allMappings));
      setUpdatedSpecMappings(_.cloneDeep(allMappings));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const getSectionById = (sectionId: string) => {
    return _.find(sections, { id: sectionId });
  };
  const syncSpc = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOnSave(true);
    try {
      setIsLoading(true);

      const response = await axiosPrivate.post("sync_spec_models");
      await axiosPrivate.post("sync_spec_segments");
      setIsLoading(false);
      toast.success("Successfully synced!");
      setOnSave(false);
    } catch (e) {
      setIsLoading(false);
      setOnSave(false);
      toast.error("Failed!");
    }
  };
  const getLabelsBySectionId = (sectionId: string) => {
    return _.find(sections, { id: sectionId }).labels;
  };
  const getMappingsBySectionId = (sectionId: string) => {
    // console.log(_.find(updatedSpecLabelMappings, { sectionId: sectionId }));
    return (
      _.find(updatedSpecLabelMappings, { sectionId: sectionId }) || {
        mappings: [],
      }
    );
  };
  const saveSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // let indexes: any = [];
    if (!!modelItemId) {
      setOnSave(true);
      setInvalidItemIdIndexes([]);
      let invalidIndex = -1;
      for (var i = 0; i < updatedSpecMappings.length; i++) {
        for (var j = 0; j < updatedSpecMappings[i].mappings.length; j++) {
          if (
            !updatedSpecMappings[i].mappings[j].labelId ||
            !updatedSpecMappings[i].mappings[j].specText
          ) {
            invalidIndex = i;
            break;
          }
        }
        if (invalidIndex > -1) {
          break;
        }
      }
      if (invalidIndex > -1) {
        setActiveTab(invalidIndex);
        setSpecMappings(_.cloneDeep(updatedSpecMappings));
        toast.error("Validation failed!");
      }
      if (invalidIndex == -1) {
        try {
          let allSpecMappings = _.cloneDeep(updatedSpecMappings);
          setSpecMappings(_.cloneDeep(allSpecMappings));
          setUpdatedSpecMappings(_.cloneDeep(allSpecMappings));
          setIsLoading(true);

          const response = await axiosPrivate.post("save_spec_model_mappings", {
            itemId: modelItemId,
            sections: updatedSpecMappings,
          });
          setIsLoading(false);
          toast.success("Successfully saved!");
          setOnSave(false);
          // await getSpecConfigurations();
          // setActiveTab(0);
        } catch (e) {
          setIsLoading(false);
          setOnSave(false);
          toast.error("Failed!");
        }
      }
      invalidIndex = -1;
    }
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <button className="btn btn-success" onClick={saveSpcConfiguration}>
          Save
        </button>
        <button className="btn btn-primary m-l-5" onClick={syncSpc}>
          Sync
        </button>
        <div className="row m-t-10">
          <div className="col-md-4">
            <select
              value={modelItemId}
              className="form-select"
              aria-label="Default select example"
              onChange={async (e) => {
                setModelItemId(e.target.value);
                try {
                  if (!!e.target.value) {
                    await getModelBomByModelItemId(e.target.value);
                    await getSpecModelMappingsByModelItemIdAndMap(
                      e.target.value
                    );
                    // if (_.isEmpty(sections)) {
                    //   let secs = await getSpecConfigurations();
                    //   specTemplate.sections = _.cloneDeep(secs);
                    // } else {
                    //   specTemplate.sections = _.cloneDeep(sections);
                    // }
                    // let modelName =
                    //   e.target.options[e.target.selectedIndex].text;
                    // specTemplate.model = modelName;
                    // setSpecTemplate(_.cloneDeep(specTemplate));
                    setIsLoading(false);
                  } else {
                    // clearAllData();
                    setSpecMappings([]);
                    setUpdatedSpecMappings([]);
                  }
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              <option value="">Select Model</option>
              {specModels.map((model: any) => (
                <option key={uuid()} value={model.itemId}>
                  {model.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {specMappings.map((specMapping: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSpecMappings(_.cloneDeep(updatedSpecMappings));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{getSectionById(specMapping.sectionId).name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {specMappings.map((specMapping: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              <div className="row m-t-5">
                <div className="col-md-3">Label</div>
                <div className="col-md-1">Item Id</div>
                <div className="col-md-8">Spec Text</div>
              </div>

              <div className="property-container">
                {getMappingsBySectionId(specMapping.sectionId).mappings.map(
                  (modelMapping: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-md-3">
                          <select
                            className={`form-control`}
                            defaultValue={modelMapping.labelId}
                            disabled
                          >
                            <option key={uuid()} value=""></option>
                            {getLabelsBySectionId(specMapping.sectionId).map(
                              (option: any) => (
                                <option key={uuid()} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="col-md-1">
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Item Id"
                            defaultValue={modelMapping.itemId}
                            disabled
                          />
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            id={`spec-text-${ind}`}
                            className={`form-control`}
                            placeholder="Spec Text"
                            defaultValue={modelMapping.specText}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="property-container">
                {specMapping.mappings &&
                  specMapping.mappings.map((mapping: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-md-3">
                          {/* <input
                            type="text"
                            id={`spec-label-${ind}`}
                            className={`form-control spec-input ${
                              onSave && !mapping.label ? "error-field" : ""
                            }`}
                            placeholder="Label"
                            defaultValue={mapping.label}
                            onChange={(e) => {
                              let val = e.target.value;
                              updatedSpecMappings[index].mappings[ind].label =
                                val;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                          /> */}
                          <select
                            className={`form-control ${
                              onSave && !mapping.labelId ? "error-field" : ""
                            }`}
                            defaultValue={mapping.labelId}
                            onChange={(event) => {
                              updatedSpecMappings[index].mappings[ind].labelId =
                                event.target.value;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                          >
                            <option key={uuid()} value="">
                              Select Label
                            </option>
                            {getLabelsBySectionId(specMapping.sectionId).map(
                              (option: any) => (
                                <option key={uuid()} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="col-md-1">
                          <input
                            type="text"
                            id={`spec-item-id-${ind}`}
                            className={`form-control`}
                            placeholder=""
                            disabled
                          />
                        </div>
                        <div className="col-md-8 d-flex">
                          <div className="flex-grow-1">
                            <input
                              type="text"
                              className={`form-control spec-input ${
                                onSave && !mapping.specText ? "error-field" : ""
                              }`}
                              placeholder="Spec Text"
                              defaultValue={mapping.specText}
                              onChange={(e) => {
                                let val = e.target.value;
                                updatedSpecMappings[index].mappings[
                                  ind
                                ].specText = val;
                                setUpdatedSpecMappings(updatedSpecMappings);
                              }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-sm"
                              onClick={() => {
                                let allMappings =
                                  _.cloneDeep(updatedSpecMappings);
                                allMappings[index].mappings.splice(ind, 1);
                                // setSections(_.cloneDeep(allSections));
                                // setUpdatedSections(_.cloneDeep(allSections));

                                setSpecMappings(_.cloneDeep(allMappings));
                                setUpdatedSpecMappings(
                                  _.cloneDeep(allMappings)
                                );
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex w-100 justify-content-end p-b-10">
                <button
                  className="btn btn-sm btn-success"
                  onClick={(event) => {
                    let allMappings = _.cloneDeep(updatedSpecMappings);
                    allMappings[index].mappings.push({
                      // sectionId: specMapping.sectionId,
                      labelId: "",
                      specText: "",
                    });
                    setSpecMappings(_.cloneDeep(allMappings));
                    setUpdatedSpecMappings(_.cloneDeep(allMappings));
                  }}
                >
                  Add New Mapping
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default SpecModelMapper;
